import { Component, EventEmitter, Input, OnInit, Output, OnChanges, SimpleChanges } from '@angular/core';
import { AbstractControl, FormControl, FormGroup, NgControl, ValidatorFn } from '@angular/forms';
import { FormArray, FormBuilder, Validators } from '@root/node_modules/@angular/forms';
import { BasicInformationService } from '@secure/products/create-product-unit/basic-information/basic-information.component.service';
import { SupportService } from '@secure/support-modal/support.service';
import { ProductCategory } from '@secure/products/models/product-creation.model';
import { ComponentsService } from '@app/shared';
import { TranslateService } from '@ngx-translate/core';
import { Store, select } from '@ngrx/store';
import { CoreState } from '@app/store';
import { ColorService } from '@app/shared/util/ColorService';


export interface ValidationRegularExpression {
  Id: number;
  Identifier: string;
  Module: string;
  Value: string;
  Description: string;
  ErrorMessage: string;
}

@Component({
  selector: 'app-product-basic-information',
  templateUrl: './product-basic-information.component.html',
  styleUrls: ['./product-basic-information.component.scss']
})
export class ProductBasicInformationComponent implements OnInit, OnChanges {
  @Input() category: ProductCategory = {} as any;
  @Input() productDetails: any;
  @Input() stepper: any;
  @Output() basicInformationStepCompleted: EventEmitter<{ basicInformationData: any, validStep: boolean }>;
  @Output() basicInformationFormEmit: EventEmitter<FormGroup> = new EventEmitter();
  basicInformationForm: FormGroup;
  validationRegularExpresions: ValidationRegularExpression[];
  productBrands = [];
  clothingSizes = [];
  clothingColors = [];
  BrandsRegex = { brandsName: '', formatIntegerNumber: '', referenceProduct: '' };

  constructor(
    private fb: FormBuilder,
    private basicInformationService: BasicInformationService,
    private SUPPORT: SupportService,
    private componentsService: ComponentsService,
    private translateService: TranslateService,
    private store: Store<CoreState>,
    private colorService: ColorService
  ) {
    this.basicInformationStepCompleted = new EventEmitter();
  }

  ngOnInit(): void {
    this.getValidationRegularExpresions();
    this.getBrands();
    this.getClothingSizes();
    this.getClothingColors();
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes['category'] && changes['category'].currentValue && this.basicInformationForm) {
      this.basicInformationForm.get('category').setValue(this.category.CategoryName);
      this.checkVariantImages();
      this.checkReferenceField();
    }
  }

  isProductEdition(): boolean {
    return !!this.productDetails;
  }

  createBasicInformationForm(): void {
    this.basicInformationForm = this.fb.group({
      name: [this.isProductEdition() ? this.productDetails.name : null, [Validators.required, Validators.pattern(this.getRegularExpresion('nameProduct')), Validators.minLength(1)]],
      category: [{ value: this.isProductEdition() ? this.productDetails.category : null, disabled: true }, Validators.required],
      brand: [this.isProductEdition() ? this.productDetails.brand : null, [Validators.required, Validators.pattern('brandProduct')]],
      shippingSize: [this.isProductEdition() ? this.productDetails.skuShippingSize : null, Validators.required],
      packingDimensions: this.fb.group({
        height: [this.isProductEdition() ? this.productDetails.packageHeight : null, [Validators.required, Validators.pattern(this.getRegularExpresion('decimalsProduct'))]],
        long: [this.isProductEdition() ? this.productDetails.packageLength : null, [Validators.required, Validators.pattern(this.getRegularExpresion('decimalsProduct'))]],
        width: [this.isProductEdition() ? this.productDetails.packageWidth : null, [Validators.required, Validators.pattern(this.getRegularExpresion('decimalsProduct'))]],
        weight: [this.isProductEdition() ? this.productDetails.packageWeight : null, [Validators.required, Validators.pattern(this.getRegularExpresion('decimalsProduct'))]],
      }),
      productDimensions: this.fb.group({
        height: [this.isProductEdition() ? this.productDetails.productHeight : null, [Validators.required, Validators.pattern(this.getRegularExpresion('decimalsProduct'))]],
        long: [this.isProductEdition() ? this.productDetails.productLength : null, [Validators.required, Validators.pattern(this.getRegularExpresion('decimalsProduct'))]],
        width: [this.isProductEdition() ? this.productDetails.productWidth : null, [Validators.required, Validators.pattern(this.getRegularExpresion('decimalsProduct'))]],
        weight: [this.isProductEdition() ? this.productDetails.productWeight : null, [Validators.required, Validators.pattern(this.getRegularExpresion('decimalsProduct'))]],
      }),
      measurementUnit: [this.isProductEdition() ? this.productDetails.measurementUnit : null, Validators.required],
      conversionFactor: [this.isProductEdition() ? this.productDetails.conversionFactor : null, [Validators.required, Validators.pattern(this.getRegularExpresion('factConversionProduct'))]],
      parentReference:this.isProductEdition()?
      [{ value: this.productDetails.parentReference, disabled :true },[ Validators.pattern(this.BrandsRegex.referenceProduct)]]:
      [null,[ Validators.pattern(this.BrandsRegex.referenceProduct)]],
      keywords: [this.isProductEdition() ? this.productDetails.keyWords.split(',') : [], Validators.required],
      keywordsValue: [null, Validators.required],
      description: [this.isProductEdition() ? this.productDetails.description : null, Validators.required],
      isCombo: [this.isProductEdition() ? this.productDetails.isCombo : false],
      eanCombo: [{ value: this.isProductEdition() ? this.productDetails.category : null, disabled: true }, Validators.pattern(this.getRegularExpresion('ean'))],
      variants: this.getVariants()
    });
     this.saveData();
  }
  
  getVariants(): FormArray {
    if (this.isProductEdition() && this.productDetails.productType === "Clothing") {
      return this.fb.array(this.productDetails.children.map(variant => this.createVariantForm(variant)), [this.minArrayLengthValidator(1)]);
    }
    return this.fb.array([]);
  }

  minArrayLengthValidator(minLength: number): ValidatorFn {
    return (control: AbstractControl): { [key: string]: any } | null => {
      if (control.value && control.value.length >= minLength) {
        return null; // Válido
      } else {
        return { minArrayLength: { valid: false, minLength: minLength } }; // Inválido
      }
    };
  }

  createVariantForm(variantData?: any): FormGroup {
    return this.fb.group({
      ean: this.isProductEdition() ? 
      [{ value:  variantData.ean , disabled:true, }] :
      [variantData ? variantData.ean : null, [Validators.required, Validators.pattern(this.getRegularExpresion('ean'))]], 
      asignatedEan: this.isProductEdition() ? [{ value: false, disabled:true}] : [variantData ? variantData.hasEAN : false],
      hasEan: this.isProductEdition(),
      size: [variantData ? variantData.size : null, [Validators.required, Validators.pattern(this.getRegularExpresion('sizeProduct'))]],
      colorName: [variantData ? variantData.hexColourName : null, [Validators.required, Validators.pattern(this.getRegularExpresion('hexColorNameProduct'))]],
      colorCode: [variantData ? variantData.hexColourCodePDP : null, Validators.required],
      imageUrl1: [variantData ? variantData.imageUrl1 : null],
      imageUrl2: [variantData ? variantData.imageUrl2 : null],
      imageUrl3: [variantData ? variantData.imageUrl3 : null],
      imageUrl4: [variantData ? variantData.imageUrl4 : null],
    }, { validators: this.eanRequiredValidator });
  }

  eanRequiredValidator(variantForm: AbstractControl) {
    const asignatedEan = variantForm.get('asignatedEan').value;
    const ean = variantForm.get('ean');
    if (!asignatedEan && !ean.value) {
      ean.setErrors({ required: true });
    } else {
      ean.setErrors(null);
    }
    return null;
  }

  onDeleteVariantForm(index: number): void {
    const variantsForm = this.basicInformationForm.get('variants') as FormArray;
    variantsForm.controls.splice(index, 1);
    variantsForm.value.splice(index, 1);
  }

  canAddProductVariants(): boolean {
    return this.category && this.category.ProductType === 'Clothing';
  }

  onAddProductVariant(): void {
    const areValidVariants = this.basicInformationForm.get('variants').valid;
    const variantsLength = this.basicInformationForm.get('variants').value.length;
    if (!variantsLength || areValidVariants) {
      const variantForm = this.createVariantForm();
      const variants = this.basicInformationForm.get('variants') as FormArray;
      variants.push(variantForm);
    } else {
      console.error('Las variantes agregadas deben ser validas');
      this.componentsService.openSnackBar(
        this.translateService.instant('secure.products.unit_product_creation.variant_product_invalid'),
        this.translateService.instant('actions.close'),
        5000
      );
    }
  }

  getValidationRegularExpresions(): void {
    const param = ['productos', null];
    this.basicInformationService.getRegexInformationBasic(param).subscribe(res => {
      this.validationRegularExpresions = JSON.parse(res.body.body).Data;
      this.getRegexByModule();
    });
  }


  getRegularExpresion(field: string): string {
    return this.validationRegularExpresions.find(regularExpresionData => regularExpresionData.Identifier === field).Value;
  }

  saveData(): void {
    this.basicInformationForm.valueChanges.subscribe(basicInformationFormData => {
      const data = {
        Name: basicInformationFormData.name,
        Brand: basicInformationFormData.brand,
        SkuShippingSize: basicInformationFormData.shippingSize,
        PackageHeight: basicInformationFormData.packingDimensions.height,
        PackageLength: basicInformationFormData.packingDimensions.long,
        PackageWidth: basicInformationFormData.packingDimensions.width,
        PackageWeight: basicInformationFormData.packingDimensions.weight,
        ProductHeight: basicInformationFormData.productDimensions.height,
        ProductLength: basicInformationFormData.productDimensions.long,
        ProductWidth: basicInformationFormData.productDimensions.width,
        ProductWeight: basicInformationFormData.productDimensions.weight,
        MeasurementUnit: basicInformationFormData.measurementUnit,
        ConversionFactor: basicInformationFormData.conversionFactor,
        ParentReference:this.category.ProductType === 'Technology'? "": (this.basicInformationForm.get("parentReference")as FormControl).value,
        KeyWords: basicInformationFormData.keywords.join(),
        Description: basicInformationFormData.description,
        IsCombo: basicInformationFormData.isCombo,
        Children: (this.basicInformationForm.get('variants') as FormArray).controls.map(control  => {
          let value = (control as FormGroup).getRawValue();
          return {
            Ean: value.ean,
            HasEAN: this.isProductEdition() ? true : !value.asignatedEan,
            Size: value.size,
            Color: value.colorCode,
            HexColourName: value.colorName,
            ImageUrl1: value.imageUrl1,
            ImageUrl2: value.imageUrl2,
            ImageUrl3: value.imageUrl3,
            ImageUrl4: value.imageUrl4,
          };
        })
      };
      this.basicInformationStepCompleted.emit({
        basicInformationData: data,        
        validStep: this.basicInformationForm.valid,
      });
      this.basicInformationFormEmit.emit(this.basicInformationForm);
    });
  }     

  getBrands(): void {
    this.basicInformationService.getActiveBrands().subscribe(brands => {
      this.productBrands = [...brands.Data.Brands];
    });
  }

  getClothingSizes() {
    this.basicInformationService.getSizeProducts().subscribe(sizes => {
      this.clothingSizes = sizes.body.data;
    });
  }

  getClothingColors() {
    this.basicInformationService.getColorProducts().subscribe(result => {
      this.clothingColors = result.body.data;
    });
  }

  checkKeywords() {
    this.basicInformationForm.get('keywordsValue').setValue(null);
    if (this.basicInformationForm.get('keywords').value.length) {
      this.basicInformationForm.get('keywordsValue').clearValidators();
    } else {
      this.basicInformationForm.get('keywordsValue').setValidators(Validators.required);
    }
    this.basicInformationForm.get('keywordsValue').updateValueAndValidity();
  }

  checkForm() {
    this.checkKeywords();
    this.basicInformationForm.markAllAsTouched();
    this.markAllAsDirty(this.basicInformationForm);
    const checkVariantImagesAreNotEmpty = this.checkVariantImagesAreNotEmpty();
    if (this.basicInformationForm.valid && checkVariantImagesAreNotEmpty) {
      this.stepper.next();
    }
  }

  checkVariantImagesAreNotEmpty() {
    let isValid = true;
    const variants = this.basicInformationForm.get('variants') as FormGroup;
    Object.values(variants.controls).forEach((child: FormGroup) => {
      if (
        !child.controls.imageUrl1.value &&
        !child.controls.imageUrl2.value &&
        !child.controls.imageUrl3.value &&
        !child.controls.imageUrl4.value
      ) {
        isValid = false;
      }
    });
    return isValid;
  }

  markAllAsDirty(form: FormGroup) {
    Object.keys(form.controls).forEach(field => {
      const control = form.get(field);
      control.markAsDirty();

      // Si el control es un FormGroup o FormArray, aplica la función recursivamente
      if (control instanceof FormGroup || control instanceof FormArray) {
        this.markAllAsDirty(control as any);
      }
    });
  }

  checkReferenceField() {
    if (this.category.ProductType === 'Technology') {
      this.basicInformationForm.get('parentReference').clearValidators();
      this.basicInformationForm.get('parentReference').setErrors(null);
    } else {
      this.basicInformationForm.get('parentReference').setValidators([Validators.required, Validators.pattern(this.BrandsRegex.referenceProduct)]);
    }
    this.basicInformationForm.get('parentReference').updateValueAndValidity();
  }

  checkVariantImages() {
    if (this.category.ProductType === 'Technology') {
     this.clearFormArray(this.basicInformationForm.get('variants') as FormArray);
      this.basicInformationForm.get('variants').clearValidators();
      this.basicInformationForm.get('variants').setErrors(null);    
    } else {
      this.basicInformationForm.get('variants').setValidators([this.minArrayLengthValidator(1)]);
    }
    this.basicInformationForm.get('variants').updateValueAndValidity();
  }

  clearFormArray(formArray: FormArray) {
    while (formArray && formArray.length !== 0 ) {
      formArray.removeAt(0);
    }
  }

  public getRegexByModule(): void {
    this.SUPPORT.getRegexFormSupport(null).subscribe(res => {
      let dataOffertRegex = JSON.parse(res.body.body);
      dataOffertRegex = dataOffertRegex.Data.filter(data => data.Module === 'parametrizacion' || data.Module === 'productos');
      for (const val in this.BrandsRegex) {
        if (!!val) {
          const element = dataOffertRegex.find(regex => regex.Identifier === val.toString());
          this.BrandsRegex[val] = element && `${element.Value}`;
        }
      }
      this.createBasicInformationForm();
    });
  }
}